import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container className="text-center">
      <Row>
        <Col>
          <p>
            Welcome to the Learn About Security corner of the internet.
          </p>
        </Col>
      </Row>


      <Row>
        <Col>
          <p>
          Hypponen’s Law:
          </p>
          <p>
          “All these connected devices, all these ‘smart’ devices in our networks. And I should know because I am the father of the Hypponen Law, which tells you that whenever something is described to you as ‘smart’ what you should be hearing is… it’s vulnerable.”
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
